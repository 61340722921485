import {
  Box,
  Flex,
  Container,
  SimpleGrid,
  Text,
  Button,
  Badge,
  Link,
  Center,
  Spinner
} from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { FaExternalLinkAlt } from "react-icons/fa";

function pathToParams(locparams) {
  const pathname = locparams;
// Remove the leading slash if present
  const pathWithoutLeadingSlash = pathname.startsWith('/') ? pathname.slice(1) : pathname;

// Split the path by slashes to get individual parameters
  const params = pathWithoutLeadingSlash.split('/');
  return params
}
export function App() {
  const [state, setState] = React.useState({});
  const [localStrings, setLocalStrings] = React.useState({});
  const [disabled , setDisabled] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  var params = new URLSearchParams(window.location.search);
 var  pathParams = pathToParams(window.location.pathname)
 var id = pathParams[0]
var source = params.get("source");
const [gridColumns, setGridColumns] = React.useState(1)

  if (id == null) {
    id = "Sample Store";
  }

  React.useEffect(() => {

    if (source == "shp") {
      source = "shopify"
      fetch(
        `https://sprout-app.thegoodapi.com/app/public/metrics?id=${id}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data["name"] == null) {
            setDisabled(true)
          }
            
          data["total_planted_trees_month"] = data["total_trees_current_month"]
          data["human_name"] = data["name"]
          data["total_planted_trees"]= data["total_trees_planted"]
          if (data["trees_enabled"] && data["plastics_enabled"]) {
            data["locale_strings"]["partnership_description"] = data["locale_strings"]["combined_description"]
            setGridColumns(2)
          } else if (data["plastics_enabled"]) {
            data["locale_strings"]["partnership_description"] = data["locale_strings"]["plastics_description"]
          }


          setState(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
        return
    }
    // fetch data from a url endpoint
    fetch(
      `https://sprout-api.thegoodapi.com/api/public/dashboard/${id}/metrics`+
        "?source=" +
        source
    )
      .then((response) => response.json())
      .then((data) => {
        data["plastics_enabled"] = false
        data["trees_enabled"] = true
        setState(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);




  function mainApp() {
    return (

      <Container minWidth="100%" p="0" bgColor="white">
      <Box pt="80px" bgColor="#EDF0F4" px={{ base: "20px", md: "0", lg: "0" }}>
        <Box m="auto" maxWidth="860px" align="center">

        <Flex direction="row" justify="center">
          {state.logo_url ? (

            <>
              <img src={state.logo_url} width="300px" height="300px" />
            </>
          ): (
            <Text fontSize="5xl" fontWeight="bold" mb="20px">{state.human_name} </Text>

          )}
          <a style={{"text-decoration": "none", "padding-left":"0%"}} href={state.url}><FaExternalLinkAlt /></a>
        </Flex>
          <Badge
            py="4px"
            px="8px"
            borderRadius="16px"
            Variant="subtle"
            colorScheme="teal"
            mb="20px"
          >
            Member since {state.member_since}
          </Badge>
          <Text fontSize="xl" fontWeight="light">
           {
           state.locale_strings?.partnership_description? state.locale_strings?.partnership_description : 
           `Our partnership with TheGoodAPI aims to combat climate change by
            reducing carbon emissions through the power of reforestation. We
            believe that planting trees is a critical step in creating a more
            sustainable future for ourselves and future generations.`
          }
          </Text>
        </Box>
      </Box>
      <Box py="80px" bgColor="#EDF0F4">
        <Box
          m="auto"
          maxWidth="860px"
          align="center"
          p={{ base: "20px", md: "0", lg: "0" }}
        >
          <Text fontSize="3xl" fontWeight="bold" mb="40px">
             {state.locale_strings?.contributions_title?  state.locale_strings?.contributions_title: `Snapshot of our contributions`}
          </Text>

          <SimpleGrid
            columns={{ base: gridColumns, md: gridColumns, lg: gridColumns }}
            spacing={{ base: "0px", md: "20px", lg: "20px" }}
          >
            {state.trees_enabled   && (
              <Box
                p={{ base: "4", md: "8", lg: "8" }}
                borderRadius="24"
                mb="20px"
                bgImage="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/6494f7c63c2e7851a851bf60_stat-tree-total.jpg"
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                color="white"
              >
                <Box
                  mb={{ base: "120px", md: "140px", lg: "140px" }}
                  align="left"
                >
                  <Text fontSize="3xl">{state.total_planted_trees}</Text>
                  <Text fontSize="xl">
                    {
                     state.locale_strings?.total_trees_planted? state.locale_strings?.total_trees_planted :`total trees planted`
                    }
                    </Text>
                </Box>
              </Box>
            )}
            {state.plastics_enabled && (
              <Box
                p={{ base: "4", md: "8", lg: "8" }}
                borderRadius="24"
                mb="20px"
                bgImage="https://storage.googleapis.com/treeshop-app-assets/dashboard.thegoodapi.com/plastics_counter_backround.png"
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                color="white"
              >
                <Box
                  mb={{ base: "120px", md: "140px", lg: "140px" }}
                  align="left"
                >
                  <Text fontSize="3xl">{state.total_bottles_registered}</Text>
                  <Text fontSize="xl">
                    {
                     state.locale_strings?.bottles_removed? state.locale_strings?.bottles_removed: `Bottles removed`
                    }
                    </Text>
                </Box> 
              </Box>
            )}            
          </SimpleGrid>
        
        </Box>
      </Box>
      <Box py="80px" bgColor="white">
        <Box
          m="auto"
          maxWidth="860px"
          align="center"
          p={{ base: "20px", md: "0", lg: "0" }}
        >
          <Text fontSize="3xl" fontWeight="bold" mb="40px">
            {  state.locale_strings?.more_then_planting_trees? state.locale_strings?.more_then_planting_trees :`More than planting trees`}
          </Text>

          <SimpleGrid
            columns={{ base: "1", md: "3", lg: "3" }}
            spacing={{ base: "0px", md: "20px", lg: "20px" }}
          >
            <Box
              p="8"
              borderRadius="24"
              mb="20px"
              bgImage="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/646be3415a4f7b0cc7a61891_vp_wildlife.jpg"
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPosition="center"
              color="white"
            >
              <Box mb={{ base: "200px", md: "260px", lg: "260px" }}>
                
                <Text fontSize="2xl">{state.locale_strings?.revive_habitats? state.locale_strings.revive_habitats:`Revive natural habitats`}</Text>
              </Box>
            </Box>
            <Box
              p="8"
              borderRadius="24"
              mb="20px"
              bgImage="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/646be341f224ea71f3040de1_vp-coral.jpg"
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPosition="center"
              color="white"
            >
              <Box mb={{ base: "200px", md: "260px", lg: "260px" }}>
                <Text fontSize="2xl">{state.locale_strings?.improve_coral_reef_health? state.locale_strings.improve_coral_reef_health:`Improve coral reef health`}</Text>
              </Box>
            </Box>
            <Box
              p="8"
              borderRadius="24"
              mb="20px"
              bgImage="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/646be41317d685d1fa75f905_vp-local.jpg"
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPosition="center"
              color="white"
            >
              <Box mb={{ base: "200px", md: "260px", lg: "260px" }}>
                <Text fontSize="2xl">{state.locale_strings?.support_locals?state.locale_strings?.support_locals:`Support local livelihoods`}</Text>
              </Box>
            </Box>
          </SimpleGrid>
          <Text fontSize="xl" fontWeight="light">
            {state.locale_strings?.improvement_summery? state.locale_strings.improvement_summery: 
            `We are dedicated to creating a more sustainable future. Our
            contributions impact the planting of mangrove trees in Madagascar.
            These reforestation efforts will not only revive natural habitats
            but also improve coral reef health, support local livelihoods, and
            aid in the fight against climate change by extracting CO2 from the
            air.`}
          </Text>
        </Box>
      </Box>
      <Box py="80px" bgColor="#04BD9C" color="white">
        <Box
          m="auto"
          maxWidth="860px"
          align="center"
          p={{ base: "20px", md: "0", lg: "0" }}
        >
          <Box width="90px" height="90px" mb="20px">
            <img src="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/646bc8ab214cf13962675386_did-you-know.png" />
          </Box>
          <Text fontSize="med" fontWeight="bold" mb="20px">
            {state.locale_strings?.did_you_know?state.locale_strings.did_you_know:`DID YOU KNOW`}
          </Text>

          <Text fontSize="3xl" fontWeight="light" mb="20px">
            {state.locale_strings?.fact_1? state.locale_strings.fact_1: `A single tree can absorb about 300kg of carbon dioxide over its
            lifetime. Planting trees and supporting reforestation efforts can
            help offset carbon emissions.`}
          </Text>
        </Box>
      </Box>
      <Box py="40px" bgColor="#3A4148" color="white">
        <Box
          m="auto"
          maxWidth="860px"
          align="center"
          p={{ base: "20px", md: "0", lg: "0" }}
        >
          <Flex>
            <Box mr="80px" align="left">
              <Text fontSize="med" fontWeight="light" align="left">
                {state.locale_strings?.goodapi_mission ? htmlSet(state.locale_strings.goodapi_mission) :
                  <>
                    Our tree planting mission is fulfilled by our partnership with <b>theGoodAPI</b>
                  </>
                }
              </Text>
            </Box>
            <Box></Box>
            <Link href="https://thegoodapi.com" isExternal>
              <Button colorScheme="teal" px="30px">
                {state.locale_strings?.join_now? state.locale_strings.join_now: `Join now`}
              </Button>
            </Link>
          </Flex>
        </Box>
      </Box>
    </Container>

    )
  }

  function htmlSet(s) {
    return (
      <div dangerouslySetInnerHTML={{ __html: s }}></div> 
    )
  }

  return (
    <>
      {isLoading ? (
        <Center h="100vh">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='teal.500'
            size='xl'
          />
        </Center>
      ) : (
        !disabled ? mainApp() : <div>/</div>
      )}
    </>
  );
}


